import React from 'react';
import {Container} from "./styles";
import {colors} from "../index";
import Loading from "../loading/loading";
import Flex from "../flex";

interface Props {
    children:React.ReactNode;
    type?:string;
    disabled?:boolean;
    big?:boolean;
    color?:string;
    flex?:string;
    loading?:boolean
    onClick?:()=> void,
    className?:string;
    mw?:number;
    id?: string | undefined;
    pb?:string;
    pt?:string;
}

const Btn:React.FC<Props> = ({
        type='',
        disabled=false,
        big,
        color= colors.blue,
        flex= "0 0 auto",
        loading= false,
        onClick= ( ) => null,
        children,
        className,
        mw=500,
        id,
        pb="5px",
        pt="5px"
 }) => {

    let height = big ? 46 : 36;

    return (
        <Container
            type={type === "line" ? '1' : undefined }
            height={height}
            flex={flex}
            color={disabled ? type === "line" ? "white" : colors.disabled : color}
            disabled={disabled}
            className={className}
            loading={loading ? '1': undefined }
            mw={mw}
            id={id}
            pb={pb}
            pt={pt}
        >
            <button style={{textDecoration: 'none !important'}} disabled={disabled} onClick={onClick}>{children}</button>
            {loading &&
            <Flex className="loading" direction={"column"}>
                <Loading
                    size={big?  40 : 30 }
                    sizeBr={big ? 5 : 3 }
                    colorLine={type === "line" ? color : "white" }
                />
            </Flex>
            }
        </Container>
    )
}

export default React.memo(Btn);